.react-tel-input .form-control {
    padding-left: 48px;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: none !important;
    border-radius: 5px;
    line-height: 25px;
    height: 46px !important;
    width: 100% !important;
    outline: none;
    background: rgba(239, 239, 239, 1);
}

.react-tel-input .flag-dropdown {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #f2e9e9;
    border-radius: 3px 0 0 3px !important;
    height: 46px !important;
    margin-left: -8px !important;
}

[dir="rtl"] .react-tel-input .form-control {
    padding-left: 18px;
    padding-right: 64px;
    direction: rtl;
}

[dir="rtl"] .react-tel-input .form-control {
    padding-left: 18px;
    padding-right: 64px;
    text-align: right;
}

[dir="rtl"] .react-tel-input .flag-dropdown {
    border-radius: 0px 12px 12px 0px !important;
}

[dir="rtl"] .react-tel-input .selected-flag {
    border-radius: 0 3px 3px 0 !important;
    padding: 0 25px 0 28px;
    width: auto;
}

[dir="rtl"] .react-tel-input .selected-flag .flag {
    right: 10px;
}

[dir="rtl"] .react-tel-input .selected-flag .arrow {
    left: -25px;
}

[dir="rtl"] .react-tel-input .flag-dropdown {
    position: absolute;
    left: auto !important;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
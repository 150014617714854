/* animations.css */

/* Fade-in and fade-out animations */
@keyframes customFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes customFadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.custom-fade-in {
    animation: customFadeIn 0.5s forwards;
}

.custom-fade-out {
    animation: customFadeOut 0.5s forwards;
}

/* Rotation animation */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.custom-rotate-image {
    animation: rotate 1.5s ease-in-out infinite;
    /* Apply rotation animation */
}

/* Pulse animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.custom-pulse-image {
    animation: pulse 1.5s ease-in-out infinite;

}

.custom-swal-popup {
    background-color: black !important;
    color: white;
    border-radius: 8px;
}